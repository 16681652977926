@import 'styles/fonts';
@import 'styles/variables';
@import 'styles/mixins';

html,
body {
  height: 100%;
  scroll-padding-top: 56px;
  background-color: $gray-1;
}

div#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gutter {
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAZCAYAAAAWuY87AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABVSURBVHgB7ZCxDYAwDARjK5ZLGC1MwGiIDWCDbOLKPaVdGZcRLW1eeunvygcRWZl5B4DHzO6acEREy5bcHVQ1yhAsn0zxX+T9feCruvtGRA0Rl9znC9DgHBLCPnavAAAAAElFTkSuQmCC');
  cursor: ew-resize;
  margin: 0 4px;
  border-radius: 16px;

  &:hover {
    background-image: none;
    background-color: var(--chakra-colors-pink-200);
  }
}

.katex {
  position: relative;
  font-size: 18px;
}
