/* vietnamese */
@font-face {
  font-family: '__Space_Mono_3e9826';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9c00aaca17e573eb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Space_Mono_3e9826';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/950a521f297881a3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Space_Mono_3e9826';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7cb331c8ee46479c-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Space_Mono_3e9826';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/80ebe8026c59a505-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Space_Mono_3e9826';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/74b0dd1b3bb245ee-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Space_Mono_3e9826';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/434045efc952350a-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Space_Mono_Fallback_3e9826';src: local("Arial");ascent-override: 80.78%;descent-override: 26.04%;line-gap-override: 0.00%;size-adjust: 138.65%
}.__className_3e9826 {font-family: '__Space_Mono_3e9826', '__Space_Mono_Fallback_3e9826';font-style: normal
}

/* kumbh-sans-latin-ext-400-normal*/
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/kumbh-sans-latin-ext-400-normal.a6843967.woff2) format('woff2'), url(/_next/static/media/kumbh-sans-all-400-normal.b883d90a.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* kumbh-sans-latin-400-normal*/
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/kumbh-sans-latin-400-normal.782bf7fd.woff2) format('woff2'), url(/_next/static/media/kumbh-sans-all-400-normal.b883d90a.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* kumbh-sans-latin-ext-700-normal*/
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/kumbh-sans-latin-ext-700-normal.1fadeafe.woff2) format('woff2'), url(/_next/static/media/kumbh-sans-all-700-normal.d5b92278.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* kumbh-sans-latin-700-normal*/
@font-face {
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/kumbh-sans-latin-700-normal.fd60618f.woff2) format('woff2'), url(/_next/static/media/kumbh-sans-all-700-normal.d5b92278.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

@font-face{font-display:swap;font-family:"Montserrat-regular";font-style:normal;font-weight:400;src:url("/fonts/Montserrat/Montserrat-Regular.ttf")}html,body{height:100%;scroll-padding-top:56px;background-color:#fcfcfc}div#__next{height:100%;display:flex;flex-direction:column}.gutter{background-repeat:no-repeat;background-position:50%;background-image:url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAZCAYAAAAWuY87AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABVSURBVHgB7ZCxDYAwDARjK5ZLGC1MwGiIDWCDbOLKPaVdGZcRLW1eeunvygcRWZl5B4DHzO6acEREy5bcHVQ1yhAsn0zxX+T9feCruvtGRA0Rl9znC9DgHBLCPnavAAAAAElFTkSuQmCC");cursor:ew-resize;margin:0 4px;border-radius:16px}.gutter:hover{background-image:none;background-color:var(--chakra-colors-pink-200)}.katex{position:relative;font-size:18px}
